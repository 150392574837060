export default [
  {
    path: '/technology/riskPlan',
    name: '风险管理计划',
    component: () => import('@/views/technology/RiskPlan')
  },
  {
    path: '/technology/riskReport',
    name: '风险管理报告',
    component: () => import('@/views/technology/RiskReport')
  },
  {
    path: '/technology/analysisReport',
    name: '项目可行性分析报告',
    component: () => import('@/views/technology/AnalysisReport')
  },
  {
    path: '/technology/projectProposal',
    name: '项目立项表',
    component: () => import('@/views/technology/ProjectProposal')
  },
  {
    path: '/technology/inputList',
    name: '设计开发输入清单',
    component: () => import('@/views/technology/InputList')
  },
  {
    path: '/technology/outputList',
    name: '设计开发输出清单',
    component: () => import('@/views/technology/OutputList')
  },
  {
    path: '/technology/reviewReport',
    name: '设计开发评审报告',
    component: () => import('@/views/technology/ReviewReport')
  },
  {
    path: '/technology/verificationReport',
    name: '设计开发验证报告',
    component: () => import('@/views/technology/VerificationReport')
  },
  {
    path: '/technology/confirmationReport',
    name: '设计开发确认报告',
    component: () => import('@/views/technology/ConfirmationReport')
  },
  {
    path: '/technology/changeForm',
    name: '设计更改申请单',
    component: () => import('@/views/technology/ChangeForm')
  },
  {
    path: '/technology/changeNotice',
    name: '设计更改通知单',
    component: () => import('@/views/technology/ChangeNotice')
  },
  {
    path: '/technology/specialReview',
    name: '特殊过程评审表',
    component: () => import('@/views/technology/SpecialReview')
  },
  {
    path: '/technology/specialReviewReport',
    name: '特殊过程评审报告',
    component: () => import('@/views/technology/SpecialReviewReport')
  }
]
