import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'
import { getToken } from '@/common/token'
import system from '@/router/system'
import purchase from '@/router/purchase'
import produce from '@/router/produce'
import device from '@/router/device'
import sale from '@/router/sale'
import technology from '@/router/technology'
import humanManagement from '@/router/humanManagement'
import storage from '@/router/storage'
import universal from '@/router/universal'
import quality from '@/router/quality'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          path: '/',
          name: '首页',
          component: () => import('@/views/Main')
        },
        {
          path: '/print',
          name: '产品出货单打印',
          component: () => import('@/views/print/index')
        },
        {
          path: '/print2',
          name: '销货通知单打印',
          component: () => import('@/views/print/index2')
        },
        {
          path: '/print3',
          name: '样品领用单打印',
          component: () => import('@/views/print/index3')
        },
        {
          path: '/my/myReview',
          name: '审核',
          component: () => import('@/views/my/MyReview')
        },
        ...system,
        ...purchase,
        ...produce,
        ...device,
        ...sale,
        ...technology,
        ...humanManagement,
        ...storage,
        ...universal,
        ...quality
      ]
    },
    {
      path: '/login',
      component: () => import('@/views/Login')
    }
  ]
})

NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.path !== '/login' && !getToken()) {
    next('/login')
    return
  }
  if (to.name) {
    const tagList = store.state.tagList.slice(0)
    if (tagList.length === 0) {
      tagList.push({ path: '/', name: '首页', closable: false, effect: 'dark' })
      store.commit('tagList', tagList)
    }
    let isExist = false
    for (let i = 0; i < tagList.length; i++) {
      if (tagList[i].path === to.path) {
        isExist = true
        tagList[i].effect = 'dark'
      } else {
        tagList[i].effect = 'plain'
      }
    }
    if (!isExist) {
      tagList.push({ path: to.path, name: to.name, closable: true, effect: 'dark' })
      store.commit('tagList', tagList)
    }
  }
  next()
})
/** 解决跳转重复路由报错问题 */
const routerPush = router.push
router.push = path => {  // 判断下当前路由是否就是要跳转的路由
  // if (router.currentRoute.fullPath.includes(path) && path != '/') return
  // return routerPush.call(router, path)
  if (router.currentRoute.path === path) return
  return routerPush.call(router, path)
}

router.afterEach(() => {
  NProgress.done()
})

export default router
