export default [
  {
    path: '/universal/checkOrder',
    name: '请验单',
    component: () => import('@/views/universal/CheckOrder')
  },
  {
    path: '/universal/assetPurchase',
    name: '资产购买申请单',
    component: () => import('@/views/universal/AssetPurchase')
  },
  {
    path: '/universal/deviceUse',
    name: '仪器设备使用记录',
    component: () => import('@/views/universal/DeviceUse')
  },
  {
    path: '/universal/personnelRequirement',
    name: '人员需求申请表',
    component: () => import('@/views/universal/PersonnelRequirement')
  },
  {
    path: '/universal/humidityRecord',
    name: '温湿度记录',
    component: () => import('@/views/universal/HumidityRecord')
  },
  {
    path: '/universal/projectProposal',
    name: '项目建议书',
    component: () => import('@/views/universal/ProjectProposal')
  },
  {
    path: '/universal/specialProcesses',
    name: '关键工序和特殊过程清单',
    component: () => import('@/views/universal/SpecialProcesses')
  },
  {
    path: '/universal/qualityInfo',
    name: '质量信息反馈表',
    component: () => import('@/views/universal/QualityInfo')
  },
  {
    path: '/universal/advisoryNotice',
    name: '忠告性通知发布记录',
    component: () => import('@/views/universal/AdvisoryNotice')
  },
  {
    path: '/universal/productRecallApproval',
    name: '产品召回审批单',
    component: () => import('@/views/universal/ProductRecallApproval')
  },
  {
    path: '/universal/medicalDeviceRecall',
    name: '医疗器械召回计划',
    component: () => import('@/views/universal/MedicalDeviceRecall')
  },
  {
    path: '/universal/productRecallAccount',
    name: '产品召回台帐',
    component: () => import('@/views/universal/ProductRecallAccount')
  },
  {
    path: '/universal/recallPlanReport',
    name: '召回计划实施情况报告',
    component: () => import('@/views/universal/RecallPlanReport')
  },
  {
    path: '/universal/transferWindowClean',
    name: '传递窗清洁灭菌记录',
    component: () => import('@/views/universal/TransferWindowClean')
  },
  //
  {
    path: '/universal/assetRetirement',
    name: '固定资产报废申请单',
    component: () => import('@/views/universal/AssetRetirement')
  },
  {
    path: '/universal/materialRequisition',
    name: '领料单',
    component: () => import('@/views/universal/MaterialRequisition')
  },
  {
    path: '/universal/orderIn',
    name: '入库单',
    component: () => import('@/views/universal/OrderIn')
  },
  {
    path: '/universal/deviceRetirement',
    name: '设备报废申请单',
    component: () => import('@/views/universal/DeviceRetirement')
  },
  {
    path: '/universal/deviceSealEnable',
    name: '设备封存（启用）申请单',
    component: () => import('@/views/universal/DeviceSealEnable')
  },
  {
    path: '/universal/deviceCheck',
    name: '设施验收单',
    component: () => import('@/views/universal/DeviceCheck')
  },
  {
    path: '/universal/qualityInformationFeedback',
    name: '质量信息反馈表',
    component: () => import('@/views/universal/QualityInformationFeedback')
  },
  {
    path: '/universal/equipmentUse',
    name: '设备使用、维护保养记录',
    component: () => import('@/views/universal/EquipmentUse')
  },
  {
    path: '/universal/disinfectantCollection',
    name: '消毒剂/洗涤剂领用申请表',
    component: () => import('@/views/universal/DisinfectantCollection')
  }
]
