export default [
  {
    path: '/purchase/product',
    name: '物料管理',
    component: () => import('@/views/purchase/Product')
  },
  {
    path: '/purchase/supplier',
    name: '供方能力评价',
    component: () => import('@/views/purchase/Supplier')
  },
  {
    path: '/purchase/annual',
    name: '年度供方评价',
    component: () => import('@/views/purchase/Annual')
  },
  {
    path: '/purchase/plan',
    name: '采购计划',
    component: () => import('@/views/purchase/Plan')
  },
  {
    path: '/purchase/first',
    name: '入库初检',
    component: () => import('@/views/purchase/First')
  },
  {
    path: '/purchase/handover',
    name: '入库交接',
    component: () => import('@/views/purchase/Handover')
  },
  //
  {
    path: '/purchase/requisition',
    name: '采购申请单',
    component: () => import('@/views/purchase/Requisition')
  },
  {
    path: '/purchase/qualifiedSupplier',
    name: '合格供方名录',
    component: () => import('@/views/purchase/QualifiedSupplier')
  },
  {
    path: '/purchase/shoppingList',
    name: '采购清单',
    component: () => import('@/views/purchase/ShoppingList')
  },
  {
    path: '/purchase/schedule',
    name: '采购计划表',
    component: () => import('@/views/purchase/Schedule')
  },
  {
    path: '/purchase/ledger',
    name: '采购台账',
    component: () => import('@/views/purchase/Ledger')
  }
]
