export default [
    {
      path: '/humanManagement/employeeList',
      name: '员工花名册',
      component: () => import('@/views/humanManagement/employeeList')
    },
    {
      path: '/humanManagement/trainingYear',
      name: '年度培训计划',
      component: () => import('@/views/humanManagement/trainingYear')
    },
    {
      path: '/humanManagement/meetingRecord',
      name: '培训/会议记录',
      component: () => import('@/views/humanManagement/meetingRecord')
    },
    {
      path: '/humanManagement/meetingSignRecord',
      name: '培训/会议签到表',
      component: () => import('@/views/humanManagement/meetingSignRecord')
    },
    {
      path: '/humanManagement/manageFileList',
      name: '受控文件清单',
      component: () => import('@/views/humanManagement/manageFileList')
    },
    {
      path: '/humanManagement/manageExternalFileList',
      name: '外来文件清单',
      component: () => import('@/views/humanManagement/manageExternalFileList')
    },
    {
      path: '/humanManagement/manageFileSignList',
      name: '文件归档登记表',
      component: () => import('@/views/humanManagement/manageFileSignList')
    },
    {
      path: '/humanManagement/manageFileUpdateList',
      name: '文件更改申请表',
      component: () => import('@/views/humanManagement/manageFileUpdateList')
    },
    {
      path: '/humanManagement/manageFileDestroyList',
      name: '文件作废/销毁申请表',
      component: () => import('@/views/humanManagement/manageFileDestroyList')
    },
    {
      path: '/humanManagement/employeeTrainingList',
      name: '员工培训记录',
      component: () => import('@/views/humanManagement/employeeTrainingList')
    },
    {
      path: '/humanManagement/employeeConversionList',
      name: '员工转正申请',
      component: () => import('@/views/humanManagement/employeeConversionList')
    },
  ]
  