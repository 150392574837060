export default [
  {
    path: '/produce/airMachineRecords',
    name: '空调操作记录',
    component: () => import('@/views/produce/airMachineRecords')
  },
  {
    path: '/produce/waterSystemClear',
    name: '水系统消毒记录',
    component: () => import('@/views/produce/waterSystemClear')
  },
  {
    path: '/produce/strangerRecordList',
    name: '外来人口登记表',
    component: () => import('@/views/produce/strangerRecordList')
  },
  {
    path: '/produce/uniformClearList',
    name: '工作服清洗记录',
    component: () => import('@/views/produce/uniformClearList')
  },
  {
    path: '/produce/uniformCountList',
    name: '工作服领用记录',
    component: () => import('@/views/produce/uniformCountList')
  },
  {
    path: '/produce/autoMaskMachineRecords',
    name: '全自动口罩机清洁记录',
    component: () => import('@/views/produce/autoMaskMachineRecords')
  },
  {
    path: '/produce/productCheckList',
    name: '生产记录审核单',
    component: () => import('@/views/produce/productCheckList')
  },
  {
    path: '/produce/clearWorkList',
    name: '清场工作记录',
    component: () => import('@/views/produce/clearWorkList')
  },
  {
    path: '/produce/waterMachineRecords',
    name: '纯化水设备操作记录',
    component: () => import('@/views/produce/waterMachineRecords')
  },
  //
  {
    path: '/produce/deviceMaintenance',
    name: '设施设备维修保养记录',
    component: () => import('@/views/produce/DeviceMaintenance')
  },
  {
    path: '/produce/outsidersCleanRoomRegister',
    name: '外来人员进出洁净室(区)登记表',
    component: () => import('@/views/produce/OutsidersCleanRoomRegister')
  },
  {
    path: '/produce/handDisinfection',
    name: '手消毒记录',
    component: () => import('@/views/produce/HandDisinfection')
  },
  {
    path: '/produce/clothesDisinfection',
    name: '工作服、鞋清洗消毒记录',
    component: () => import('@/views/produce/ClothesDisinfection')
  },
  {
    path: '/produce/clothesIssuance',
    name: '工作服、鞋领用发放记录',
    component: () => import('@/views/produce/ClothesIssuance')
  },
  {
    path: '/produce/workstationLedger',
    name: '工位器具台账',
    component: () => import('@/views/produce/WorkstationLedger')
  },
  {
    path: '/produce/cleaningToolsDisinfection',
    name: '清洁工具清洁消毒记录',
    component: () => import('@/views/produce/CleaningToolsDisinfection')
  },
  {
    path: '/produce/ozoneDisinfection',
    name: '洁净区臭氧消毒记录',
    component: () => import('@/views/produce/OzoneDisinfection')
  },
  {
    path: '/produce/plan',
    name: '生产计划表',
    component: () => import('@/views/produce/Plan')
  },
  {
    path: '/produce/batchInstructionNotice',
    name: '批生产指令通知单',
    component: () => import('@/views/produce/BatchInstructionNotice')
  },
  {
    path: '/produce/disinfectantMakeIssue',
    name: '消毒剂配制和发放记录',
    component: () => import('@/views/produce/DisinfectantMakeIssue')
  },
  {
    path: '/produce/detergentMakeIssue',
    name: '洗涤剂配制和发放记录',
    component: () => import('@/views/produce/DetergentMakeIssue')
  },
  {
    path: '/produce/surgicalElectrodeCard',
    name: '一次性使用手术电极生产流程卡',
    component: () => import('@/views/produce/SurgicalElectrodeCard')
  },
  {
    path: '/produce/neutralElectrodeCard',
    name: '(带线)一次性使用中性电极生产流程卡',
    component: () => import('@/views/produce/NeutralElectrodeCard')
  },
  {
    path: '/produce/neutralElectrodeCard2',
    name: '(无线)一次性使用中性电极生产流程卡',
    component: () => import('@/views/produce/NeutralElectrodeCard2')
  },
  {
    path: '/produce/surgicalElectrodeSterilization',
    name: '一次性使用手术电极灭菌记录表',
    component: () => import('@/views/produce/SurgicalElectrodeSterilization')
  },
  {
    path: '/produce/plantHumidity',
    name: '厂房温湿度记录表',
    component: () => import('@/views/produce/PlantHumidity')
  },
  {
    path: '/produce/plantPressure',
    name: '厂房压差记录表',
    component: () => import('@/views/produce/PlantPressure')
  }
]
