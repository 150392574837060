export default [
  {
    path: '/device/acceptance',
    name: '设备验收单',
    component: () => import('@/views/device/Acceptance')
  },
  {
    path: '/device/maintainPlan',
    name: '设备维护保养计划',
    component: () => import('@/views/device/MaintainPlan')
  },
  {
    path: '/device/maintainRecord',
    name: '设备维修保养记录',
    component: () => import('@/views/device/MaintainRecord')
  },
  {
    path: '/device/retirement',
    name: '设备报废单',
    component: () => import('@/views/device/Retirement')
  },
  {
    path: '/device/air',
    name: '空调过滤器清洗更换记录',
    component: () => import('@/views/device/Air')
  },
  {
    path: '/device/filter',
    name: '高效过滤器检漏记录',
    component: () => import('@/views/device/Filter')
  },
  {
    path: '/device/filterReplace',
    name: '水系统滤芯更换记录',
    component: () => import('@/views/device/FilterReplace')
  },
  {
    path: '/device/mask',
    name: '口罩机每周维护检查表',
    component: () => import('@/views/device/Mask')
  },
  //
  {
    path: '/device/baseInfo',
    name: '基础设施台帐',
    component: () => import('@/views/device/BaseInfo')
  },
  {
    path: '/device/purifiedWaterMonitor',
    name: '纯化水系统监测记录',
    component: () => import('@/views/device/PurifiedWaterMonitor')
  },
  {
    path: '/device/purifiedWaterDisinfection',
    name: '纯化水系统消毒记录',
    component: () => import('@/views/device/PurifiedWaterDisinfection')
  },
  {
    path: '/device/purifiedWaterUserTending',
    name: '纯化水系统使用、维护保养记录',
    component: () => import('@/views/device/PurifiedWaterUserTending')
  }
]
