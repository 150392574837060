<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
html, body, #app {
  margin: 0;
  padding: 0;
  height: 100%;
  /* overflow: hidden; */
}
.el-table .review {
  background: oldlace;
}
.el-form-item__label {
  font-weight: bold;
}
.el-select {
  width: 100%;
}
.el-input-number {
  width: 100%;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
.el-dialog {
  margin-top: 10px !important;
}
.el-dialog__header {
  padding-bottom: 20px;
}
.el-dialog__footer {
  padding-top: 0;
}
.el-dialog__body {
  padding: 0;
  padding-right: 20px;
}
.el-pagination {
  margin-top: 10px;
  float: right;
}
.el-select-tree {
  width: 100%;
}
.el-input:focus {
  outline: none
}
.el-dropdown-selfdefine:focus {
  outline: none
}
.el-tooltip:focus {
  outline: none
}
.vxe-select--panel {
  z-index: 3000 !important;
}
.vxe-input--panel {
  z-index: 3000 !important;
}
.vxe-table--tooltip-wrapper {
  z-index: 3000 !important;
}
.vxe-radio .vxe-radio--label {
  display: inline !important;
}
/* ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
} */
/* #nprogress .bar {
  background: green !important;
} */
.el-select-dropdown {
  z-index: 9999 !important;
}
.el-table__body tr.current-row > td {
  background-color: NavajoWhite !important;
  /* color: #1E90FF; */ /* 设置文字颜色，可以选择不设置 */
}
.el-input.is-disabled .el-input__inner,.el-textarea.is-disabled .el-textarea__inner{
  color:black!important;
}

</style>
