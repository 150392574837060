export default [
  {
    path: '/quality/waterQualityRecord',
    name: '工艺用水水质日常监测',
    component: () => import('@/views/quality/WaterQualityRecord')
  },
  {
    path: '/quality/disinfectant',
    name: '消毒剂配制记录',
    component: () => import('@/views/quality/Disinfectant')
  },
  {
    path: '/quality/sampling',
    name: '取样记录',
    component: () => import('@/views/quality/SamplingRecord')
  },
  {
    path: '/quality/ledger',
    name: '不合格品台账',
    component: () => import('@/views/quality/UnqualifiedProductLedger')
  },
  {
    path: '/quality/adverseEventReport',
    name: '可疑医疗器械不良事件报告',
    component: () => import('@/views/quality/AdverseEventReport')
  },
  {
    path: '/quality/adverseEventAdditional',
    name: '医疗器械不良事件补充报告',
    component: () => import('@/views/quality/AdverseEventAdditional')
  },
  {
    path: '/quality/adverseEventAnnual',
    name: '医疗器械不良事件年度汇总报告表',
    component: () => import('@/views/quality/AdverseEventAnnual')
  },
  {
    path: '/quality/correctMeasures',
    name: '纠正和预防措施实施表',
    component: () => import('@/views/quality/CorrectMeasures')
  },
  {
    path: '/quality/recallReport',
    name: '医疗器械召回事件报告',
    component: () => import('@/views/quality/RecallReport')
  },
  {
    path: '/quality/materialReportNose',
    name: '鼻夹原料检验报告书',
    component: () => import('@/views/quality/MaterialReportNose')
  },
  {
    path: '/quality/materialReportEar',
    name: '耳带原料检验报告书',
    component: () => import('@/views/quality/MaterialReportEar')
  },
  {
    path: '/quality/materialReportCertificate',
    name: '合格证原料检验报告书',
    component: () => import('@/views/quality/MaterialReportCertificate')
  },
  {
    path: '/quality/materialReportInner',
    name: '内包装材料原料检验报告书',
    component: () => import('@/views/quality/MaterialReportInner')
  },
  {
    path: '/quality/materialReportMeltblown',
    name: '熔喷布无纺布原料检验报告',
    component: () => import('@/views/quality/MaterialReportMeltblown')
  },
  {
    path: '/quality/materialReportInstructions',
    name: '说明书原料检验报告书',
    component: () => import('@/views/quality/MaterialReportInstructions')
  },
  {
    path: '/quality/materialReportCarton',
    name: '纸箱原料检验报告书',
    component: () => import('@/views/quality/MaterialReportCarton')
  },
  {
    path: '/quality/maskProcess',
    name: '一次性使用医用口罩过程检验记录',
    component: () => import('@/views/quality/MaskProcess')
  },
  {
    path: '/quality/maskFinished',
    name: '一次性使用医用口罩成品检验记录',
    component: () => import('@/views/quality/MaskFinished')
  },
  {
    path: '/quality/materialReportMask',
    name: '一次性使用医用口罩非灭菌检验报告书',
    component: () => import('@/views/quality/MaterialReportMask')
  },
  {
    path: '/quality/illumination',
    name: '照度检测记录',
    component: () => import('@/views/quality/IlluminationTestRecord')
  },
  {
    path: '/quality/auditChecklist',
    name: '内部审核检查表',
    component: () => import('@/views/quality/InternalAuditChecklist')
  },
  {
    path: '/quality/bacteriaUseDestroy',
    name: '菌种使用及销毁',
    component: () => import('@/views/quality/BacteriaUseDestroy')
  },
  {
    path: '/quality/bacteriaSolution',
    name: '菌液制备、使用、销毁记录',
    component: () => import('@/views/quality/BacteriaSolution')
  },
  {
    path: '/quality/reviewPlan',
    name: '管理评审计划',
    component: () => import('@/views/quality/ManagementReviewPlan')
  },
  {
    path: '/quality/reviewReport',
    name: '管理评审报告',
    component: () => import('@/views/quality/ManagementReviewReport')
  },
  {
    path: '/quality/annualAuditPlan',
    name: '年度内部审核计划',
    component: () => import('@/views/quality/AnnualInternalAuditPlan')
  },
  {
    path: '/quality/auditPlan',
    name: '内部审核实施计划',
    component: () => import('@/views/quality/InternalAuditPlan')
  },
  {
    path: '/quality/auditReport',
    name: '内部审核报告',
    component: () => import('@/views/quality/InternalAuditReport')
  },
  {
    path: '/quality/bacteria',
    name: '菌种外购',
    component: () => import('@/views/quality/Bacteria')
  },
  {
    path: '/quality/standardSolution',
    name: '标准溶液配制',
    component: () => import('@/views/quality/StandardSolutionPreparation')
  },
  {
    path: '/quality/fingerDetection',
    name: '生产人员手指菌检测记录',
    component: () => import('@/views/quality/FingerBacteriaDetection')
  },
  {
    path: '/quality/cleanRoomHumidity',
    name: '洁净室（区）温湿度、静压差监测记录',
    component: () => import('@/views/quality/CleanRoomHumidityPressure')
  },
  {
    path: '/quality/testSolution',
    name: '试液、指示液、缓冲溶液配制记录',
    component: () => import('@/views/quality/TestSolution')
  },
  {
    path: '/quality/cleanRoomWind',
    name: '洁净室（区）风速风量、换气次数监测记录',
    component: () => import('@/views/quality/CleanRoomWind')
  },
  {
    path: '/quality/titrant',
    name: '滴定液配制、标定(复标)',
    component: () => import('@/views/quality/Titrant')
  },
  {
    path: '/quality/medium',
    name: '计数用培养基适用性检查记录',
    component: () => import('@/views/quality/MediumCheck')
  },
  {
    path: '/quality/initialDetection',
    name: '待灭菌产品初始污染菌检测记录',
    component: () => import('@/views/quality/InitialBacteriaDetection')
  },
  {
    path: '/quality/inspectionNose',
    name: '(鼻夹)一次性使用医用口罩原材料检验记录',
    component: () => import('@/views/quality/RawMaterialInspectionNose')
  },
  {
    path: '/quality/inspectionEar',
    name: '(耳带)一次性使用医用口罩原材料检验记录',
    component: () => import('@/views/quality/RawMaterialInspectionEar')
  },
  {
    path: '/quality/inspectionMeltblown',
    name: '(无纺布、熔喷布)一次性使用医用口罩原材料检验记录',
    component: () => import('@/views/quality/RawMaterialInspectionMeltblown')
  },
  {
    path: '/quality/waterDailyMonitor',
    name: '纯化水常规监控记录',
    component: () => import('@/views/quality/WaterDailyMonitorRecord')
  },
  {
    path: '/quality/dustDailyRecord',
    name: '尘埃粒子监控记录',
    component: () => import('@/views/quality/DustDailyRecord')
  },
  // 
  {
    path: '/quality/pressureRecord',
    name: '压差监测记录',
    component: () => import('@/views/quality/PressureRecord')
  },
  {
    path: '/quality/humidityRecord',
    name: '温湿度记录表',
    component: () => import('@/views/quality/HumidityRecord')
  },
  {
    path: '/quality/settlingBacteriaRecord',
    name: '沉降菌检测记录',
    component: () => import('@/views/quality/SettlingBacteriaRecord')
  },
  {
    path: '/quality/airChangeRecord',
    name: '换气次数检测记录',
    component: () => import('@/views/quality/AirChangeRecord')
  },
  {
    path: '/quality/stabilityTestWorkPlan',
    name: '稳定性试验工作计划表',
    component: () => import('@/views/quality/StabilityTestWorkPlan')
  },
  {
    path: '/quality/glassLedger',
    name: '玻璃仪器校验台账',
    component: () => import('@/views/quality/GlassLedger')
  },
  {
    path: '/quality/reagentUseIssueRecord',
    name: '试剂试药领用、发放记录',
    component: () => import('@/views/quality/ReagentUseIssueRecord')
  },
  {
    path: '/quality/cultureMedium',
    name: '培养基配制、灭菌记录',
    component: () => import('@/views/quality/CultureMedium')
  },
  {
    path: '/quality/bacteriaInfo',
    name: '菌种保存、领用及销毁登记台账',
    component: () => import('@/views/quality/BacteriaInfo')
  },
  {
    path: '/quality/measuringToolsPlan',
    name: '计量器具周期检定计划表',
    component: () => import('@/views/quality/MeasuringToolsPlan')
  },
  {
    path: '/quality/measuringTools',
    name: '计量器具台账',
    component: () => import('@/views/quality/MeasuringTools')
  },
  {
    path: '/quality/finishedProductAudit',
    name: '成品放行审核单',
    component: () => import('@/views/quality/FinishedProductAudit')
  },
  {
    path: '/quality/incomingInspectionReport',
    name: '进货检验报告',
    component: () => import('@/views/quality/IncomingInspectionReport')
  },
  {
    path: '/quality/surgicalElectrodeTestRecord',
    name: '一次性使用手术电极过程检验记录',
    component: () => import('@/views/quality/SurgicalElectrodeTestRecord')
  },
  {
    path: '/quality/neutralElectrodeTestRecord',
    name: '一次性使用中性电极过程检验记录',
    component: () => import('@/views/quality/NeutralElectrodeTestRecord')
  },
  {
    path: '/quality/surgicalElectrodeFactoryReport',
    name: '一次性使用手术电极出厂检验报告',
    component: () => import('@/views/quality/SurgicalElectrodeFactoryReport')
  },
  {
    path: '/quality/neutralElectrodeFactoryReport',
    name: '一次性使用中性电极出厂检验报告',
    component: () => import('@/views/quality/NeutralElectrodeFactoryReport')
  },
  {
    path: '/quality/microbeInspectionReport',
    name: '微生物检验报告',
    component: () => import('@/views/quality/MicrobeInspectionReport')
  },
  {
    path: '/quality/waterCheck',
    name: '纯化水检测记录',
    component: () => import('@/views/quality/WaterCheck')
  },
  {
    path: '/quality/ethyleneOxideReport',
    name: '环氧乙烷残留量检验报告',
    component: () => import('@/views/quality/EthyleneOxideReport')
  },
  {
    path: '/quality/particulatePollutionReport',
    name: '微粒污染检验报告',
    component: () => import('@/views/quality/ParticulatePollutionReport')
  },
  {
    path: '/quality/sterilityReport',
    name: '无菌检验报告',
    component: () => import('@/views/quality/SterilityReport')
  },
  {
    path: '/quality/initialBacteriaReport',
    name: '初始污染菌检验报告',
    component: () => import('@/views/quality/InitialBacteriaReport')
  },
  {
    path: '/quality/bacteriaSterilization',
    name: '实验中废弃的带菌物品灭菌处理记录',
    component: () => import('@/views/quality/BacteriaSterilization')
  },
  {
    path: '/quality/laboratoryHumidity',
    name: '实验室温湿度记录表',
    component: () => import('@/views/quality/LaboratoryHumidity')
  },
  {
    path: '/quality/laboratoryPressure',
    name: '实验室压差记录表',
    component: () => import('@/views/quality/LaboratoryPressure')
  },
  {
    path: '/quality/unqualifiedProducts',
    name: '不合格品处理单',
    component: () => import('@/views/quality/UnqualifiedProducts')
  },
  {
    path: '/quality/reserveSampleLedger',
    name: '留样台账及领用记录',
    component: () => import('@/views/quality/ReserveSampleLedger')
  },
  {
    path: '/quality/hazardousChemicals',
    name: '危险化学品领、发记录',
    component: () => import('@/views/quality/HazardousChemicals')
  },
  {
    path: '/quality/sampleInvestigation',
    name: '留样考察记录',
    component: () => import('@/views/quality/SampleInvestigation')
  }
]
