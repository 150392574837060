export default [
  {
    path: '/storage/pickList',
    name: '领料单',
    component: () => import('@/views/storage/PickList')
  },
  {
    path: '/storage/inLedger',
    name: '入库总账',
    component: () => import('@/views/storage/InLedger')
  },
  {
    path: '/storage/inLedgers',
    name: '入库分类账',
    component: () => import('@/views/storage/InLedgers')
  },
  {
    path: '/storage/cargoCard',
    name: '货位卡',
    component: () => import('@/views/storage/CargoCard')
  },
  {
    path: '/storage/returnOrder',
    name: '退库单',
    component: () => import('@/views/storage/ReturnOrder')
  },
  {
    path: '/storage/productIdCard',
    name: '物料产品标志卡',
    component: () => import('@/views/storage/ProductIdCard')
  },
  {
    path: '/storage/disinfectant',
    name: '消毒剂领用',
    component: () => import('@/views/storage/Disinfectant')
  },
  {
    path: '/storage/sample',
    name: '产品留样台账及领用',
    component: () => import('@/views/storage/Sample')
  },
  {
    path: '/storage/reagentInOut',
    name: '试剂试药入库领用发放',
    component: () => import('@/views/storage/ReagentInOut')
  },
  {
    path: '/storage/reagent',
    name: '试液发放',
    component: () => import('@/views/storage/Reagent')
  },
  {
    path: '/storage/chemical',
    name: '危险化学品领发',
    component: () => import('@/views/storage/Chemical')
  },
  {
    path: '/storage/cultureLedger',
    name: '培养基总帐',
    component: () => import('@/views/storage/CultureLedger')
  },
  {
    path: '/storage/culture',
    name: '培养基发放使用',
    component: () => import('@/views/storage/Culture')
  },
  {
    path: '/storage/finishedInLedger',
    name: '成品入库总账',
    component: () => import('@/views/storage/FinishedInLedger')
  },
  {
    path: '/storage/finishedOutLedgers',
    name: '成品出库分类账',
    component: () => import('@/views/storage/FinishedOutLedgers')
  },
  {
    path: '/storage/finishedReturnList',
    name: '成品退库处理单',
    component: () => import('@/views/storage/FinishedReturnList')
  },
  //
  {
    path: '/storage/materialPurchase',
    name: '内销仓库原材料出入库',
    component: () => import('@/views/storage/MaterialPurchase')
  },
  {
    path: '/storage/materialPurchaseHistory',
    name: '内销仓库原材料出入库历史',
    component: () => import('@/views/storage/MaterialPurchaseHistory')
  },
  {
    path: '/storage/materialPurchaseStock',
    name: '内销仓库原材料管理',
    component: () => import('@/views/storage/MaterialPurchaseStock')
  },
  {
    path: '/storage/materialProduct',
    name: '内销仓库产品出入库',
    component: () => import('@/views/storage/MaterialProduct')
  },
  {
    path: '/storage/materialProductHistory',
    name: '内销仓库产品出入库历史',
    component: () => import('@/views/storage/MaterialProductHistory')
  },
  {
    path: '/storage/materialProductStock',
    name: '内销仓库产品管理',
    component: () => import('@/views/storage/MaterialProductStock')
  },
  {
    path: '/storage/materialReagent',
    name: '试剂试药管理',
    component: () => import('@/views/storage/MaterialReagent')
  },
  {
    path: '/storage/materialOther',
    name: '其他',
    component: () => import('@/views/storage/MaterialOther')
  },
  {
    path: '/storage/materialPurchase2',
    name: '外销仓库原材料出入库',
    component: () => import('@/views/storage/MaterialPurchase2')
  },
  {
    path: '/storage/materialPurchaseStock2',
    name: '外销仓库原材料管理',
    component: () => import('@/views/storage/MaterialPurchaseStock2')
  },
  {
    path: '/storage/materialProduct2',
    name: '外销仓库产品出入库',
    component: () => import('@/views/storage/MaterialProduct2')
  },
  {
    path: '/storage/materialProductStock2',
    name: '外销仓库产品管理',
    component: () => import('@/views/storage/MaterialProductStock2')
  },
  {
    path: '/storage/orderOut',
    name: '出库单',
    component: () => import('@/views/storage/OrderOut')
  },
  {
    path: '/storage/orderCheck',
    name: '请验单',
    component: () => import('@/views/storage/OrderCheck')
  },
  {
    path: '/storage/inFirst',
    name: '入库初检记录',
    component: () => import('@/views/storage/InFirst')
  },
  {
    path: '/storage/warehouseHumidity',
    name: '仓库温湿度记录表',
    component: () => import('@/views/storage/WarehouseHumidity')
  },
  {
    path: '/storage/deliveryOrder',
    name: '出货单',
    component: () => import('@/views/storage/DeliveryOrder')
  },
  {
    path: '/storage/test',
    name: '入库初检记录-测试',
    component: () => import('@/views/storage/Test')
  }
]
