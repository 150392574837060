export default [
  {
    path: '/user',
    name: '用户管理',
    component: () => import('@/views/system/User')
  },
  {
    path: '/dept',
    name: '部门管理',
    component: () => import('@/views/system/Dept')
  },
  {
    path: '/menu',
    name: '菜单管理',
    component: () => import('@/views/system/Menu')
  },
  {
    path: '/role',
    name: '角色管理',
    component: () => import('@/views/system/Role')
  },
  {
    path: '/dict',
    name: '字典管理',
    component: () => import('@/views/system/Dict')
  }
]
