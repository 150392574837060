export default [
  {
    path: '/sale/product',
    name: '产品信息',
    component: () => import('@/views/sale/Product')
  },
  {
    path: '/sale/deliveryNotice',
    name: '发货通知单',
    component: () => import('@/views/sale/DeliveryNotice')
  },
  {
    path: '/sale/deliveryNote',
    name: '产品送货单',
    component: () => import('@/views/sale/DeliveryNote')
  },
  //
  {
    path: '/sale/record',
    name: '销售记录',
    component: () => import('@/views/sale/Record')
  },
  {
    path: '/sale/notice',
    name: '销货通知单',
    component: () => import('@/views/sale/Notice')
  },
  {
    path: '/sale/orderReview',
    name: '订单评审表',
    component: () => import('@/views/sale/OrderReview')
  },
  {
    path: '/sale/feedbackInfo',
    name: '顾客反馈信息记录表',
    component: () => import('@/views/sale/FeedbackInfo')
  },
  {
    path: '/sale/customer',
    name: '客户信息登记表',
    component: () => import('@/views/sale/Customer')
  },
  {
    path: '/sale/satisfactionSurvey',
    name: '顾客满意度调查表',
    component: () => import('@/views/sale/SatisfactionSurvey')
  },
  {
    path: '/sale/feedbackSurvey',
    name: '顾客反馈调查记录',
    component: () => import('@/views/sale/FeedbackSurvey')
  },
  {
    path: '/sale/satisfactionReport',
    name: '顾客满意度报告',
    component: () => import('@/views/sale/SatisfactionReport')
  },
  {
    path: '/sale/returnApplication',
    name: '退货申请单',
    component: () => import('@/views/sale/ReturnApplication')
  },
  {
    path: '/sale/certificate',
    name: '医疗器械证书',
    component: () => import('@/views/sale/Certificate')
  },
  {
    path: '/sale/sampleRequisition',
    name: '样品领用单',
    component: () => import('@/views/sale/SampleRequisition')
  }
]
